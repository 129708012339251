<template>
  <transition name="fade">
    <div
      v-if="splash.splashScreen"
      class="fixed top-0 left-0 w-full h-full bg-white"
      style="z-index: 1000"
    >
      <div
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
        <div
          title="Beesphone"
          class="fade-in-out text-5xl font-bold text-primary font-manrope"
        >
          Beesphone
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
const splash = useSplashScreenStore();
</script>

<style scroped>
.fade-in-out {
  animation: fade-in-out 1s ease-in-out infinite;
}

@keyframes fade-in-out {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
